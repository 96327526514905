import Repository from '../../services/repositories/RepositoryFactory';

const UserRepository = Repository.service('accounts');

export default {
  namespaced: true,
  // ----------------------------------------------------------------------------------
  state: {
    activeAccount: {
      username: null,
    },
    accounts: [],
  },
  // ----------------------------------------------------------------------------------
  getters: {
    getActiveAccount(state) {
      return state.activeAccount;
    },
    getAccounts(state) {
      return state.accounts;
    },
  },
  // ----------------------------------------------------------------------------------
  mutations: {
    setActiveAccount(state, newActiveAccount) {
      state.activeAccount = newActiveAccount;
    },
    setAccounts(state, newAccountsList) {
      state.accounts = newAccountsList;
    },
  },
  // ----------------------------------------------------------------------------------
  actions: {
    async getAccountToken(_, account) {
      try {
        const response = await UserRepository.getAccountToken(account);
        return response;
      } catch (error) {
        return error;
      }
    },

    async getAccountsList({ commit }, body) {
      try {
        const response = await UserRepository.getAccountsList(body);
        commit("setAccounts", response);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};
