import api from '../clients/axios';

const resource = 'access';

export default {
  async getAccountToken(account) {
    const response = await api.get(`${resource}/${account}`);
    return response.data;
  },

  async getAccountsList(body) {
    const response = await api.get(`${resource}`, { params: body });
    return response.data;
  },
};
